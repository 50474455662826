body {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #a9a9cfc2;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.205);
}
