@media only screen and (max-width: 1760px) {
  .container {
    width: 100vw !important;
  }

  .paper-container {
    margin-left: 0% !important;
  }
}

@media only screen and (max-width: 1080px) {
  .paper-container {
    width: 80% !important;
  }
}

@media only screen and (max-width: 600px) {
  .paper-container {
    height: 0 !important;
    width: 100% !important;
  }

  .form {
    width: 98% !important;
    height: 100% !important;
  }
}
